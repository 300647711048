import { XMarkIcon } from "@heroicons/react/24/outline";
import { useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { FuelRateList } from "../../types/interfaces/FuelData";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import EditFuelModal from "./EditFuelrate";

interface Props {
  setShowFuelRate: Function;
  fuelRates: FuelRateList[];
  handleAllFuelRates: Function;
}

const FuelDataModel: React.FC<Props> = ({
  setShowFuelRate,
  fuelRates,
  handleAllFuelRates,
}) => {
  const currency = localStorage.getItem("currency") || "USD";
  const ref = useRef<HTMLDivElement>(null);
  const [showFuelModal, setShowFuelModal] = useState(false);
  const [selectedFuelRate, setSelectedFuelRate] = useState<FuelRateList | null>(
    null
  );

  console.log(
    "fuelModal",
    showFuelModal,
    "editFuelRate",
    selectedFuelRate,
    "editFuelModal",
    EditFuelModal
  );

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const formatDateRange = (dateFrom: string, dateTo: string) => {
    const from = new Date(dateFrom);
    const to = new Date(dateTo);
    return `${from.toLocaleDateString()} - ${to.toLocaleDateString()}`;
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        ref={ref}
        className="bg-white rounded-lg shadow-lg p-5 w-full max-w-lg"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-gray-800">
            Fuel Rates (per Ltr.)
          </h2>
          <button
            aria-label="Close modal"
            onClick={() => setShowFuelRate(false)}
          >
            <XMarkIcon className="w-6 h-6 text-gray-600 hover:text-gray-800" />
          </button>
        </div>

        <div className="overflow-y-auto" style={{ maxHeight: "300px" }}>
          <table className="min-w-full table-auto">
            <thead>
              <tr className="bg-gray-200">
                <th className="py-2 px-4 border-b text-left text-xs font-bold text-black uppercase">
                  Date Range
                </th>
                <th className="py-2 px-4 border-b text-left text-xs font-bold text-black uppercase">
                  Amount
                </th>
                <th className="py-2 px-4 border-b text-left text-xs font-bold text-black uppercase">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {fuelRates.map((rate) => (
                <tr key={rate.ID} className="hover:bg-gray-50">
                  <td className="py-2 px-4 border-b text-sm">
                    {formatDateRange(rate.DateFrom, rate.DateTo)}
                  </td>
                  <td className="py-2 px-4 border-b text-sm">
                    {formatCurrency(rate.Amount)}
                  </td>
                  <td className="py-2 px-4 border-b text-sm">
                    <button
                      className="text-blue-500"
                      onClick={() => {
                        setSelectedFuelRate(rate);
                        setShowFuelModal(true);
                      }}
                    >
                      <PencilSquareIcon className="w-5 h-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showFuelModal && selectedFuelRate && (
        <EditFuelModal
          setShowFuelModal={setShowFuelModal}
          amount={selectedFuelRate?.Amount.toString()}
          dateFrom={new Date(selectedFuelRate?.DateFrom)}
          dateTo={new Date(selectedFuelRate?.DateTo)}
          id={selectedFuelRate?.ID}
        />
      )}
    </div>
  );
};

export default FuelDataModel;
