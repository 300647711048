import { CalendarDaysIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import {
  fetchAllFuels,
  updateFuelRate,
} from "../../services/fuelAllowance.service";
import { UpdateFuel } from "../../types/interfaces/FuelData";

interface Props {
  setShowFuelModal: Function;
  amount: string;
  dateTo?: Date;
  dateFrom?: Date;
  id: string | undefined;
}

const EditFuelModal: React.FC<Props> = ({
  setShowFuelModal,
  amount,
  dateTo,
  dateFrom,
  id,
}) => {
  const currency = localStorage.getItem("currency");
  const ref = useRef<HTMLDivElement>(null);
  const [startDate, setStartDate] = useState<Date | null>(dateFrom || null);
  const [endDate, setEndDate] = useState<Date | null>(dateTo || null);
  const [fuelAmount, setFuelAmount] = useState<string>(`${currency} ${amount}`);
  const [amountError, setAmountError] = useState<string | null>(null);

  useOnClickOutside(ref, () => setShowFuelModal(false));

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.preventDefault();
  };

  const formatCurrency = (value: string) => {
    const numericValue = value.replace(/[^0-9.]/g, "");

    if (!numericValue) return "";

    const parts = numericValue.split(".");
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const decimalPart =
      parts[1] !== undefined ? `.${parts[1].substring(0, 2)}` : "";
    return `${currency} ${integerPart}${decimalPart}`;
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    setFuelAmount(formatCurrency(value));
    setAmountError(null);
  };

  const handleBlur = () => {
    setFuelAmount((prevValue) => formatCurrency(prevValue));
  };

  const handleNewFuelRate = async () => {
    if (
      !startDate ||
      !endDate ||
      isNaN(parseFloat(fuelAmount.replace(/[^0-9.]/g, "")))
    ) {
      toast.error("All fields are required");
      return;
    }

    const dateFrom = new Date(
      Date.UTC(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      )
    );
    const dateTo = new Date(
      Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
    );
    const numericFuelAmount = parseFloat(fuelAmount.replace(/[^0-9.]/g, ""));
    var reqBody: UpdateFuel = {
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
      amount: numericFuelAmount,
      id: id ? id : "",
    };
    const resp: any = await updateFuelRate(reqBody);
    console.log(resp, "update fuel api response");
    if (resp.res) {
      toast.success(resp.res.message);
      setShowFuelModal(false);
    } else {
      toast.error(resp.err.message);
      setShowFuelModal(false);
    }
  };

  const handleCancelClick = () => {
    setShowFuelModal(false);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div ref={ref} className="bg-white rounded-lg p-5 w-[490px]">
        <div className="flex justify-between items-baseline">
          <h2 className="text-lg font-semibold mb-4">Add Updated Fuel Rate</h2>
          <button aria-label="Close modal">
            <XMarkIcon
              className="w-6 h-6"
              onClick={() => setShowFuelModal(false)}
            />
          </button>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Fuel Rate (per Ltr.)
          </label>
          <input
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            type="text"
            placeholder="Price"
            value={fuelAmount}
            onChange={handleAmountChange}
            onBlur={handleBlur}
          />
          {amountError && <p className="text-red-500 text-sm">{amountError}</p>}
        </div>

        <div className="relative mb-3 md:mb-0 flex items-center z-10">
          <ReactDatePicker
            className="cursor-pointer rounded-lg min-w-[230px] w-full bg-[#ffffff] border border-[#D6D6D6] text-sm py-3 px-5 placeholder:text-[#333] placeholder:font-medium"
            onChange={handleDateChange}
            startDate={startDate || undefined}
            endDate={endDate || undefined}
            placeholderText="Select Range"
            selectsRange={true}
            dateFormat="dd/MM/yyyy"
            preventOpenOnFocus
            onKeyDown={handleKeyDown}
          />
          {endDate ? (
            <button className="absolute top-3 right-4" onClick={handleClear}>
              <XMarkIcon className="text-black w-5 h-5" />
            </button>
          ) : (
            <span className="absolute top-3 right-4 pointer-events-none">
              <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
            </span>
          )}
        </div>
        <div className="flex justify-end gap-2 my-4">
          <button
            className="text-sm font-semibold text-[#333333] py-2.5 px-3 rounded-lg border-[#D4D4D4] border w-[72px]"
            onClick={handleCancelClick}
          >
            Cancel
          </button>
          <button
            className="bg-[#378EB5] text-white rounded px-4 py-2"
            onClick={handleNewFuelRate}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditFuelModal;
